.collapse {
  display: block !important;
}

.print-only {
  display: block !important;
}

.m-hero__top {
  background: none !important;
}

.hide-print,
.a-btn,
header,
footer,
.m-hero--course .m-breadcrumb,
.m-breadcrumb {
  display: none !important;
}

.print-logo {
  margin-bottom: 2rem;
}
.print-logo img {
  margin: 0 auto;
  display: block;
  height: auto;
  max-width: 150px;
}

main {
  padding-top: 10px !important;
}

.m-expand {
  border: none !important;
  padding-top: 5px !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-bottom: 0 !important;
}
.m-expand .m-expand-header {
  margin-bottom: 1rem;
  background-image: none !important;
}
.m-expand .m-expand__content {
  padding-left: 0 !important;
}
.m-expand .m-expand__content ul {
  margin-bottom: 1rem;
  margin-top: 0;
}

.m-hero,
.m-hero.m-hero--course {
  margin-bottom: 2rem;
}
.m-hero h1,
.m-hero.m-hero--course h1 {
  color: #1a6eb7;
}
.m-hero .m-hero__content,
.m-hero.m-hero--course .m-hero__content {
  padding-top: 1rem;
}
.m-hero .m-hero__bottom p,
.m-hero.m-hero--course .m-hero__bottom p {
  margin-top: 0;
}

ul li,
p {
  font-size: 0.875rem !important;
}

.m-expand__content {
  padding-top: 0 !important;
}

html {
  font-size: 80% !important;
}

p, ul, li, span {
  color: black !important;
}

.o-section {
  margin-top: 10px !important;
}
.o-section .o-section__content p + p {
  margin-top: 1rem;
}
.o-section .o-section__content .m-expand:first-child {
  margin-top: 0;
}
.o-section.o-section-getintouchstripone, .o-section.o-section--inverse {
  background: none !important;
  padding: 2rem 0;
}
.o-section.o-section-getintouchstripone .o-section__content, .o-section.o-section--inverse .o-section__content {
  margin-top: 2rem;
}
.o-section.o-section-getintouchstripone h3, .o-section.o-section--inverse h3 {
  color: #1a6eb7;
}
.o-section.o-section-getintouchstripone .o-section__icon-text, .o-section.o-section--inverse .o-section__icon-text {
  color: black;
  margin-left: 0;
  margin-bottom: 1rem;
}
.o-section.o-section-getintouchstripone .o-section__content {
  margin-top: 0;
}